import PropTypes from "prop-types";
import React from "react";

const MainContent = ({ children, hasArticle, hasBanner }) => (
  <main className={`content ${hasArticle ? "has-article" : ""} ${hasBanner ? "has-banner" : ""}`} id='article'>
    <div className='grid grid-padding'>
      {children}
    </div>
  </main>
);

MainContent.propTypes = {
  children: PropTypes.node.isRequired,
  hasArticle: PropTypes.bool,
  hasBanner: PropTypes.bool
};

MainContent.defaultProps = {
  hasArticle: true,
  hasBanner: false
};

export default MainContent;
