import Layout from "../components/layout";
import { Link } from "gatsby";
import MainContent from "../components/maincontent";
import PageBanner from "../components/banner/banner";
import React from "react";
import Seo from "../components/seo";

const currentYear = "2024";
const dateRange = "October 9th - October 13th";

const IndexPage = () => (
  <Layout>
    <Seo
      title="Home"
      description="A local assembly in central Illinois"
      lang="en"
      meta={[
        {
          content: "God, Jesus, Gospel, Heaven, Salvation, Saved, Grace, Free, How, Mid-Acts, Romans, Charity Grace Bible Church, Illinois",
          name: "keywords"
        }
      ]}
    />

    <PageBanner
      hasImage={true}
      hasOverlay={true}
      imageUrl="/assets/images/img_preaching.jpg"
    />

    <MainContent
      hasArticle={false}
      hasBanner={true}
    >
      <h1 className='main-header'>{currentYear}</h1>

      <div className="card">
        <div className="grid grid--12">

          <div className="grid--4 align--middle">
            <img src="/assets/images/camp2020.jpg" alt="A&nbsp;group&nbsp;of&nbsp;people&nbsp;stand&nbsp;in&nbsp;front&nbsp;of&nbsp;a&nbsp;chapel." />
          </div>

          <div className="grid--8 align--left">
            <h2>Online Registration for {currentYear} is now available!</h2>
            <p>The {currentYear} Charity Grace Bible Camp Registration is Live! Camp will be held {dateRange}.</p>
            <p>Should you have any questions or special concerns regarding camp, please contact us at
              <a className="link link--blue" href="mailto:charitygracebiblechurch@gmail.com?Subject=ATTN_Mary_CampQuestion" target="_top">charitygracebiblechurch@gmail.com</a>.
            </p>
            <Link className="button button--green no-margin" to="/camp/register/">Register Now</Link>
          </div>

        </div>
      </div>

      <div className="card">
        <div className="grid grid--12">

          <div className="grid--4 align--middle">
            <img src="/assets/images/camp2020.jpg" alt="A&nbsp;group&nbsp;of&nbsp;people&nbsp;stand&nbsp;in&nbsp;front&nbsp;of&nbsp;a&nbsp;chapel." />
          </div>

          <div className="grid--8 align--left">
            <h2>{currentYear} Camp Schedule ({dateRange})</h2>
            <p><strong>Posted: May 31th, 2024 - 8:00PM</strong></p>
            <p>The {currentYear} Charity Grace Bible Camp Schedule is Now Available! Camp will be held {dateRange}</p>
            <Link className="button button--blue no-margin" to={`/camp/${currentYear}/`}>View Schedule</Link>
          </div>

        </div>
      </div>

      <h1 className='main-header'>Historical</h1>

      <div className="card">
        <div className="grid grid--12">

          <div className="grid--4 align--middle">
            <img src="/assets/images/camp2020.jpg" alt="A&nbsp;group&nbsp;of&nbsp;people&nbsp;stand&nbsp;in&nbsp;front&nbsp;of&nbsp;a&nbsp;chapel." />
          </div>

          <div className="grid--8 align--left">
            <h2>2023 Camp Schedule (Oct 12th - 15th)</h2>
            <p><strong>Posted: June 25th, 2023 - 5:00PM</strong></p>
            <p>2023 Schedule for historical purposes.</p>
            <Link className="button button--blue no-margin" to={`/camp/2023/`}>View Schedule</Link>
          </div>

        </div>
      </div>

      <div className="card">
        <div className="grid grid--12">

          <div className="grid--4 align--middle">
            <img src="/assets/images/camp2020.jpg" alt="A&nbsp;group&nbsp;of&nbsp;people&nbsp;stand&nbsp;in&nbsp;front&nbsp;of&nbsp;a&nbsp;chapel." />
          </div>

          <div className="grid--8 align--left">
            <h2>2022 Camp Schedule (Oct 6th - 9th)</h2>
            <p><strong>Posted: May 5th, 2022 - 5:00PM</strong></p>
            <p>2022 Schedule for historical purposes.</p>
            <Link className="button button--blue no-margin" to="/camp/2022/">View Schedule</Link>
          </div>

        </div>
      </div>

      <div className="card">
        <div className="grid grid--12">

          <div className="grid--4 align--middle">
            <img src="/assets/images/camp2020.jpg" alt="A&nbsp;group&nbsp;of&nbsp;people&nbsp;stand&nbsp;in&nbsp;front&nbsp;of&nbsp;a&nbsp;chapel." />
          </div>

          <div className="grid--8 align--left">
            <h2>2021 Camp Schedule</h2>
            <p><strong>Posted: April 27th, 2021 - 5:00PM</strong></p>
            <p>2021 Schedule for historical purposes.</p>
            <Link className="button button--blue no-margin" to="/camp/2021/">View Schedule</Link>
          </div>

        </div>
      </div>

      <div className="card">
        <div className="grid grid--12">

          <div className="grid--4 align--middle">
            <img src="/assets/images/camp2019.jpg" alt="A&nbsp;group&nbsp;of&nbsp;people&nbsp;stand&nbsp;in&nbsp;front&nbsp;of&nbsp;a&nbsp;chapel." />
          </div>

          <div className="grid--8 align--left">
            <h2>2020 Camp Schedule</h2>
            <p><strong>Posted: August 24th, 2020 - 9:56PM</strong></p>
            <p>2020 Schedule for historical purposes.</p>
            <Link className="button button--blue no-margin" to="/camp/2020/">View Schedule</Link>
          </div>

        </div>
      </div>

      <div className="card">
        <div className="grid grid--12">

          <div className="grid--4 align--middle">
            <img src="/assets/images/camp2018.jpg" alt="A&nbsp;group&nbsp;of&nbsp;people&nbsp;stand&nbsp;in&nbsp;front&nbsp;of&nbsp;a&nbsp;chapel." />
          </div>

          <div className="grid--8 align--left">
            <h2>2019 Camp Schedule</h2>
            <p>2019 Schedule for historical purposes.</p>
            <Link className="button button--blue no-margin" to="/camp/2019/">View Schedule</Link>
          </div>

        </div>
      </div>

    </MainContent>
  </Layout>
);

export default IndexPage;
