import PropTypes from "prop-types";
import React from "react";

const PageBanner = ({ children, hasImage, hasOverlay, imageUrl }) => (
  <React.Fragment>
    <section
      style={imageUrl !== null ? { backgroundImage: `url(${imageUrl})` } : {}}
      className={
        `banner--flex
    ${hasImage ?
    "has-image" : ""}
    ${hasOverlay ?
    "has-overlay" : ""}`}
    >
      {children}
    </section>
  </React.Fragment>
);

PageBanner.propTypes = {
  children: PropTypes.node,
  hasImage: PropTypes.bool,
  hasOverlay: PropTypes.bool,
  imageUrl: PropTypes.string
};

PageBanner.defaultProps = {
  children: null,
  hasImage: false,
  hasOverlay: false,
  imageUrl: null
};

export default PageBanner;
